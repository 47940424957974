<template>
	<div class="outer-wrapper">
		<div class="dates">
			<div class="fixed-top outer-wrapper">
				<router-link :to="{ 
					name: 'Flights Locations',
				}">
					<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
				</router-link>
				<!-- <router-link :to="{ name: 'Home' }">
					<img src="@/assets/close-icon.png" alt="close" class="close-icon"/>
				</router-link> -->
				<div class="trip-type-holder">
					<input type="radio" v-model="flightsSearch.searchValues.tripType" value="oneway" id="oneway" @click="flightsSearch.searchValues.dates = new Date(formattedDates.start)">
					<label class="oneway-label" for="oneway">One Way</label>
					<input 
						type="radio" 
						v-model="flightsSearch.searchValues.tripType" 
						value="return" 
						id="return" 
						@click="flightsSearch.searchValues.dates = { start: new Date(formattedDates.start), end: '' }">
					<label class="return-label" for="return">Return</label>
				</div>
				<div class="selected-dates">
					<div class="depart date-section">
						Depart
						<div class="date">
							{{ formatDate(formattedDates.start) }}
						</div>
					</div>
					<div class="return date-section">
						Return
						<div class="date">
							{{ formatDate(formattedDates.end) }}
						</div>
					</div>
					<div style="clear: both"></div>
				</div>
			</div>

			<div class="inner-wrapper picker">
				<v-date-picker 
					class="datepicker scroll"
					v-model="flightsSearch.searchValues.dates"
					mode="date" 
					:is-range="isReturn"
					color="customblue"
					:min-date="new Date()"
					:rows="13"
					trim-weeks
					:step="1"
				>
					<a slot='header-left-button'></a> <!-- Hide the left/right arrows -->
					<a slot='header-right-button'></a>
				</v-date-picker>
			</div>
			<div class="fixed-bottom">
				<button class="button" v-on:click="submit()" :class="{ disabled: !checkForm }">Continue</button>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState, mapActions, mapGetters } from 'vuex'
	import router from '@/router';
	import helpers from '@/helpers/helpers.js';

	export default {
		name: 'flightsSearchDates',
		data() {
			return {
				errors: new Map(),
			}
		},
		components: {
		},
		computed: {
			...mapState({
				flightsSearch: state => state.flightsSearch,
			}),
			...mapGetters('flightsSearch', ['formattedDates', 'isReturn']),
			tripType() {
				if(this.isReturn) {
					return "Return Trip";
				} else { 
					return "One way";
				}
			},
			checkForm() {
				this.errors = new Map();
				let dateErrors = [];
				if(this.formattedDates.start == null || this.formattedDates.start == "") {
					dateErrors.push('You must select a start date');
				}
				if(this.isReturn) {
					if(this.formattedDates.end == null || this.formattedDates.end == "") {
						dateErrors.push('You must select a return date');
					}
				}
				if(dateErrors.length > 0) {
					this.errors.set('dates', dateErrors);
				}
				if (!this.errors.size > 0) {
					return true;
				}
				return false;
				e.preventDefault();
			},
		},
		methods: {
			...mapActions({
				mapQueryParams: 'flightsSearch/mapQueryParams',
			}),
			formatDate: (date) => helpers.formatDate(date),
			submit() {
				if(this.checkForm) {
					router.push({
						name: 'Flights Search',
					})
				}
			},
		},
		created () {
			this.mapQueryParams();

			// Ensure the user can see all valid dates by resetting to current day (temporarily)
			var selectedDate = this.flightsSearch.searchValues.dates; // Store selected data temporarily
			this.flightsSearch.searchValues.dates = new Date();
			setTimeout(() => {
				this.flightsSearch.searchValues.dates = selectedDate;
			}, 100)

			this.flightsSearch.shouldSearch = true;

		},
	}
</script>

<style scoped>
	.dates {
		/* min-height: calc(100vh - 155px); */
		/* position: relative; */
	}
	.fixed-bottom {
		position: absolute;
		bottom: 0px;
		left: 0;
		width: 100%;
		z-index: 10;
		background-color: white;
	}
	
	.selected-dates {
		max-width: 100%;
		width: 300px;
		margin: 0px auto;
		margin-bottom: 10px;
	}
	.selected-dates .date-section {
		display: inline-block;
		color: #50555C;
		font-size: 12px;
	}
	.selected-dates .date-section .date {
		font-size: 16px;
		font-weight: bold;
		color: black;
	}
	.selected-dates .date-section.depart {
		float: left;
		text-align: left;
	}
	.selected-dates .date-section.return {
		float: right;
		text-align: right;
	}
	.picker {
		max-height: calc(100vh - 215px);
		overflow-y: scroll;
	}
	.datepicker.scroll {
		border: none;
		padding-top: 50px;
		padding-bottom: 50px;
		display: block;
		margin: 0 auto;
		margin-top: 80px;
	}
	.datepicker.scroll .vc-arrow {
		display: none !important;
	}

	.button {
		display: block;
		margin: 0 auto
	}
	
</style>